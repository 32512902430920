@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
  background-color: #f0f0f0;
}

a {
  text-decoration: none;
  color: #000;
}

p {
  line-height: 1.7;
  font-size: 23px;
}

ul {
  list-style: none;
}

li {
  line-height: 2.2;
}

h1,
h2,
h3 {
  font-weight: 600;
  margin-bottom: 10px;
}

.chill-btn {
  font-size: 14px;
  background-color: #000;
  color: white;
  padding: 2px 2px 2px 2px;
  border-radius: 3px;
  cursor: pointer;
}

.login-thing {
  background-color: white;
  width: 450px;
  height: 450px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.container {
  width: 100%;
  /*max-width: 960px;*/
  margin: 0 auto;
  /*padding: 0 20px;*/
  text-align: center;
  background-color: #f0f0f0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 60px;
  background-color: white;
}

.header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header ul li {
  margin-left: 20px;
}

.header ul li a {
  display: flex;
  align-items: center;
}

.header ul li a:hover {
  color: #777;
}

.header ul li a svg {
  margin-right: 5px;
}

.heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 50px;
  padding: 0 20px;
}

.heading p {
  color: #828282;
}

.my-popup-content {
  margin: auto;
  background-color: "black";
  width: 50%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #000;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}
.my-popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].my-popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.my-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].my-popup-overlay {
  background: transparent;
}

.goals {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.msgs {
  display: flex;
  flex-direction: column;
  /*grid-template-columns: repeat(2, 1fr);
  gap: 10px;*/
}

.msg {
  background-color: #f4f4f4;
  margin: 10px 0;
  padding: 20px 0 10px;
  position: relative;
  border: 1px solid #000;
  border-radius: 3px;
}

.msg .close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  border: none;
  background: none;
}

.goal {
  background-color: #d4d4d4;
  margin: 10px 0;
  padding: 20px 0 10px;
  position: relative;
}

.goal .close {
  position: absolute;
  top: 10px;
  left: 15px;
  cursor: pointer;
  border: none;
  background: none;
}

.goal .close2 {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  border: none;
  background: none;
}

.form,
.content {
  width: 75%;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

.form-group label {
  text-align: left;
  display: block;
  margin: 0 0 5px 3px;
}

.form-container {
  display: flex;
  justify-content: space-between;
  background-color: rgb(252, 252, 252);
  padding-top: 50px;
  border: solid 1px rgb(231, 231, 231);
  border-radius: 10px;
}

.dash {
  background-color: #f0f0f0;
}

.btn {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn svg {
  margin-right: 8px;
}

.btn-reverse {
  background: #fff;
  color: #000;
}

.btn-block {
  margin-bottom: 20px;
}

.btn-submit {
  width: 20vw;
  margin: auto;
  margin-bottom: 20px;
}

.btn:hover {
  transform: scale(0.98);
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .form {
    width: 90%;
  }

  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}


/* Adjust the date picker input */
.datePicker input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Style the date picker's calendar */
.react-datepicker {
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
}

.react-datepicker__header {
  background-color: white;
  border-bottom: 1px solid #ccc;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 2.5rem;
  line-height: 2.5rem;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  font-size: 1rem;
}

.react-datepicker__navigation {
  top: 1rem;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc;
}
